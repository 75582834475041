import { Accordion } from '@almbrand/accordion';
import { Button, ButtonProps, LinkButtonSettings } from '@almbrand/button';
import { RichText } from '@almbrand/richtext';

interface AccordionItem {
	question: string;
	answer: string;
	isNew: boolean;
	showLastUpdated: boolean;
	lastUpdatedText: string;
	contentType: string;
	contentId: number;
	buttons?: Button[];
	ctaMarginTop?: string;
	ctaMarginBottom?: string;
}

interface AccordionGroup {
	title?: string;
	contentType: string;
	contentId: number;
	accordionItems: {
		version: number;
		items: {
			content: AccordionItem;
		}[];
	};
}

interface AccordionComponentProps extends LocalId {
	isFaqAccordions?: boolean;
	header?: string;
	accordionsArea: {
		groups?: AccordionGroup[];
		items?: AccordionItem[];
	};
	style?: string;
	padding?: boolean;
	ctaBlock?: CTABlock;
	backgroundColor?: BackgroundColor;
	itemsBackgroundColor?: BackgroundColor;
	showUsabillaInPage?: boolean;
	contentId?: string | number;
}

export const AccordionComponent: React.FC<AccordionComponentProps> = ({
	// isFaqAccordions,
	// header,
	accordionsArea,
	ctaBlock,
	// style,
	// padding,
	// backgroundColor,
	// itemsBackgroundColor,
	// showUsabillaInPage,¨
	header,
	useReadline,
	readlineId,
	contentId,
}) => {
	const { groups, items } = accordionsArea;

	const generateCtaGroupProps = (item: AccordionItem) => {
		const ctaGroupMargins = (): string => {
			const marginTop = item.ctaMarginTop !== 'default' ? `pt-${item.ctaMarginTop}` : '';
			const marginBottom = item.ctaMarginBottom !== 'default' ? `pb-${item.ctaMarginBottom}` : '';

			return `${marginTop} ${marginBottom}`.trim();
		};

		return {
			ctaGroup:
				item.buttons?.map((button) => ({
					children: button?.title || '',
					buttonType: (button?.type === 'cta' ? button?.color : button?.type) as ButtonProps['buttonType'],
					linkButtonSettings: {
						href: button?.link?.url,
						target: button?.target as LinkButtonSettings['target'],
						title: button?.title,
					},
				})) || [],
			className: ctaGroupMargins(),
		};
	};

	return (
		<>
			{header && (
				<div className={'AccordionComponent__header'}>
					<RichText description={header} />
				</div>
			)}
			{groups && groups.length > 0
				? groups.map((group, index) => (
						<div key={index} className="AccordionComponent__groupSpacing">
							<Accordion
								header={group.title}
								items={group.accordionItems.items.map((item, itemIndex) => {
									return {
										id: itemIndex,
										title: item.content.question,
										content: item.content.answer,
										isActive: false,
										ctaGroupProps: item.content.buttons && generateCtaGroupProps(item.content),
									};
								})}
								id={useReadline && readlineId ? readlineId : contentId}
							/>
						</div>
					))
				: items.map((item, itemIndex) => {
						return (
							<Accordion
								items={[
									{
										id: itemIndex,
										title: item.question,
										content: item.answer,
										isActive: false,
										ctaGroupProps: item.buttons && generateCtaGroupProps(item),
									},
								]}
								key={itemIndex}
								id={useReadline && readlineId ? readlineId : contentId}
							/>
						);
					})}

			{ctaBlock?.title && (
				<div className="pt-spacing-md">
					<Button
						buttonType={
							(ctaBlock.btnType === 'cta'
								? ctaBlock.btnColor
								: ctaBlock.btnType) as ButtonProps['buttonType']
						}
					>
						{ctaBlock.title}
					</Button>
				</div>
			)}
		</>
	);
};
